import { ReactElement, ReactNode, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useKeycloak } from '@react-keycloak/web'

import { Loader } from '@foxino/components-web'

import { Pages } from '@app/config/router/Pages'
import { UserRole } from '@app/data'
import { useAddRoleAndClassroom } from '@app/modules/settings/data'

import { getAccessToken } from '../actions/authActions'
import { IncorrectRole } from '../components'
import { useStudentAppSupportedVersion } from '../data/useStudentAppSupportedVersion'
import { authStore } from '../store/authStore'
import { tokenStore } from '../store/tokenStore'
import { InsufficientAppVersion } from '../view/InsufficientAppVersion'

type Props = {
  children: ReactNode | ReactNode[] | null
}

export const RoleProvider = ({ children }: Props): ReactElement | null => {
  const { userInfo } = authStore.useStore(({ userInfo, authState }) => ({ userInfo, authState }))
  const { addUserRole, pending, addMeToClassroom } = useAddRoleAndClassroom()
  const navigate = useNavigate()
  const location = useLocation()
  const { keycloak } = useKeycloak()

  const newRegistrationData = tokenStore.useStore((store) => store.newRegistrationData)

  const { hasUnsupportedVersion } = useStudentAppSupportedVersion()

  const { roles } = userInfo || {}

  useEffect(() => {
    const addRole = async () => {
      const success = await addUserRole(UserRole.STUDENT)
      if (success) {
        getAccessToken(3600)
      }
    }

    if (roles && !roles.includes(UserRole.STUDENT.toLocaleLowerCase())) {
      addRole()
    }
  }, [roles?.length])

  useEffect(() => {
    if (location.pathname === '/') {
      if (keycloak.authenticated) {
        navigate(Pages.DASHBOARD_TASKS)
      }
    }
  }, [keycloak.authenticated])

  useEffect(() => {
    if (newRegistrationData && roles && roles.length > 0 && roles.includes(UserRole.STUDENT.toLocaleLowerCase())) {
      const addNewClass = async () => {
        await addMeToClassroom(newRegistrationData.classroomId, newRegistrationData.classroomLoginCode)

        tokenStore.clearRegistrationData()
      }

      addNewClass()
    }
  }, [newRegistrationData, roles])

  if (pending) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <Loader className="fill-neutral50" />
      </div>
    )
  }

  if (roles && roles.length > 0 && !roles.includes(UserRole.STUDENT.toLocaleLowerCase())) {
    return <IncorrectRole />
  }

  if (hasUnsupportedVersion) {
    return <InsufficientAppVersion />
  }

  return <>{children}</>
}
