import { useEffect, useRef } from 'react'
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom'
import { useClickAway, useToggle } from 'react-use'
import { SplashScreen } from '@capacitor/splash-screen'
import { motion } from 'framer-motion'

import { LoaderWrapper, Text } from '@app/components'
import { Pages } from '@app/config/router/Pages'
import { useBreakpoints } from '@app/hooks'
import { useTranslation } from '@app/locales'
import { MainHeader, NoClassSection } from '@app/modules/common'
import { useDashboardMenuHeight } from '@app/modules/common/layout/DashboardLayout/useDashboardMenuHeight'
import { localSettingsStore } from '@app/modules/common/store/localSettingsStore'
import { lessonInputsStore } from '@app/modules/lesson/store'
import { isCurrentPathActive } from '@app/utils/commonUtils'
import { hapticsImpactMedium } from '@app/utils/hapticsUtils'
import { logError } from '@app/utils/logsUtils'

import { AllDoneForToday, ClassroomSwitch, TaskCardBig, TaskCardSmall } from '../components'
import { PAGE_COUNT, useActiveTasks, useFinishedTasks, useStudentInfo } from '../data'
import { LearningExecutionType } from '../model/types'

const REFRESH_INTERVAL = 15000

export const TasksPage = () => {
  const [isSwitchClassBannerVisible, toggleSwitchClassBanner] = useToggle(false)
  const [isClassroomSelected, toggleClassroomSelected] = useToggle(false)

  const refreshTimer = useRef<number | null>(null)

  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { t } = useTranslation(['common', 'dashboard'])
  const classroomId = localSettingsStore.useStore((state) => state.classroomId)

  const breakpoint = useBreakpoints()
  const bottomBarHeight = useDashboardMenuHeight()

  const ref = useRef(null)
  useClickAway(ref, () => {
    if (isSwitchClassBannerVisible) {
      toggleSwitchClassBanner()
    }
  }, ['click'])

  const { studentInfo, loading: loadingStudentInfo, refetching: fetchingStudentInfo } = useStudentInfo()

  const {
    activeTasks,
    error: activeTaskError,
    loading: activeTaskLoading,
    refetching: activeTaskRefetching,
    refetch: refetchActiveTasks
  } = useActiveTasks({
    variables: {
      classroomId: isClassroomSelected ? classroomId : undefined
    }
  })

  const {
    finishedTasks,
    error: finishedTasksError,
    loading: finishedTasksLoading,
    hasMore: hasMoreFinishedTasks,
    refetching: finishedTasksRefetching,
    refetch: refetchFinishedTasks
  } = useFinishedTasks({
    variables: {
      classroomId: isClassroomSelected ? classroomId : undefined,
      page: { from: 0, count: PAGE_COUNT }
    }
  })

  const startRefreshTimer = () => {
    if (refreshTimer.current !== null) {
      clearInterval(refreshTimer.current)
    }

    refreshTimer.current = window.setInterval(() => {
      refetchActiveTasks()
      refetchFinishedTasks()
    }, REFRESH_INTERVAL)
  }

  const stopRefreshTimer = () => {
    if (refreshTimer.current !== null) {
      clearInterval(refreshTimer.current)
      refreshTimer.current = null
    }
  }

  useEffect(() => {
    if (isCurrentPathActive(pathname, Pages.DASHBOARD_TASKS, true) && !!(activeTasks || finishedTasks)) {
      refetchActiveTasks()
      refetchFinishedTasks()
    }

    // start the timer only if it's not already running
    if (refreshTimer.current === null) {
      startRefreshTimer()
    }

    const handleVisibilityChange = () => {
      if (document.hidden) {
        stopRefreshTimer()
      } else {
        startRefreshTimer()
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
      stopRefreshTimer()
    }
  }, [pathname])

  useEffect(() => {
    // start a new timer with the updated classroomId
    startRefreshTimer()

    return () => {
      stopRefreshTimer()
    }
  }, [classroomId])

  useEffect(() => {
    if (activeTaskError || finishedTasksError) {
      logError(activeTaskError || finishedTasksError, 'TasksPage', 'useEffect')
    }
  }, [activeTaskError, finishedTasksError])

  useEffect(() => {
    if (activeTasks && finishedTasks) {
      SplashScreen.hide({ fadeOutDuration: 500 })
    }
  }, [])

  const handleOnRunAssignmentClick = (
    { learningAssignment, id, remainingTimeSeconds }: LearningExecutionType,
    type: string
  ) => {
    // IMPORTANT - when starting a new learning assignment, we want to keep only the LISTENING interaction type,
    // because the user might have changed the SPEAKING permission settings in the meantime, so we cannot rely on the previous state.
    // So we need to ask for the microphone permission every time the user starts a new learning assignment
    lessonInputsStore.setNewLearningAssignment(
      id,
      learningAssignment.type,
      learningAssignment.skills,
      remainingTimeSeconds ?? undefined,
      learningAssignment.subject
    )

    hapticsImpactMedium()

    navigate({
      pathname: Pages.LESSON_BEFORE,
      search: `?${createSearchParams({ type })}`
    })
  }

  const handleOnShowMoreClick = () => {
    navigate(Pages.DASHBOARD_TASKS_FINISHED)
  }

  const handleOnFinishedTaskClick = ({ id }: LearningExecutionType, type: string) => {
    navigate(Pages.DASHBOARD_TASKS_DETAIL(id, type))
  }

  const loading =
    activeTaskLoading ||
    activeTaskRefetching ||
    finishedTasksLoading ||
    finishedTasksRefetching ||
    loadingStudentInfo ||
    fetchingStudentInfo

  return (
    <div className="relative flex h-full w-full flex-col">
      <MainHeader
        isQrLoginBtnVisible
        isXpBarVisible
        isSwitchClassEnable
        isSwitchClassBannerVisible={isSwitchClassBannerVisible}
        toggleSwitchClassBanner={toggleSwitchClassBanner}
      />

      {!loading && (!studentInfo?.student.classrooms || studentInfo?.student.classrooms.length === 0) ? (
        <NoClassSection />
      ) : (
        <>
          {isSwitchClassBannerVisible && <div className="fixed inset-0 top-0 z-[98] bg-black/50 backdrop-blur" />}

          <LoaderWrapper
            className="h-screen pb-32"
            loading={loading}
            error={
              (activeTaskError || finishedTasksError) != undefined &&
              activeTasks?.length === 0 &&
              finishedTasks?.length === 0
            }
            showChildrenOnLoading={!!activeTasks || !!finishedTasks}
          >
            <div
              style={{ paddingBottom: bottomBarHeight + 16 }}
              className="relative mt-16 flex min-h-[600px] w-full flex-col"
            >
              {activeTasks && activeTasks.length === 0 && <AllDoneForToday />}

              <div className="absolute right-4 top-4">
                <ClassroomSwitch isClassroomSelected={isClassroomSelected} toggle={toggleClassroomSelected} />
              </div>

              <motion.div
                className="flex flex-col gap-8 p-4 md:p-8"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
              >
                {activeTasks && activeTasks.length > 0 && (
                  <div className="flex w-full flex-col gap-4">
                    <span className="text-[20px] font-bold text-neutral50">{t('activities.active')}</span>
                    <div className="gap-4 md:grid md:grid-cols-2 xl:grid-cols-3">
                      <TaskCardBig task={activeTasks[0]} onClick={handleOnRunAssignmentClick} />
                    </div>
                    <div className="flex w-full flex-wrap justify-center gap-4 md:grid md:grid-cols-2 xl:grid-cols-3">
                      {activeTasks.slice(1, activeTasks.length).map((task, index) => {
                        return (
                          <TaskCardSmall
                            key={task.id}
                            isOdd={breakpoint == 'xs' || breakpoint == 'sm' ? index % 2 !== 0 : index % 3 !== 0}
                            task={task}
                            onInProgressTaskClick={handleOnRunAssignmentClick}
                          />
                        )
                      })}
                    </div>
                  </div>
                )}

                {finishedTasks && finishedTasks.length > 0 && (
                  <div className="mb-16 flex w-full flex-wrap gap-4">
                    <span className="text-[20px] font-bold text-neutral50">{t('activities.finished')}</span>
                    <div className="flex w-full flex-wrap justify-center gap-4 md:grid md:grid-cols-2 xl:grid-cols-3">
                      {finishedTasks.map((task, index) => {
                        return (
                          <TaskCardSmall
                            key={task.id}
                            isOdd={breakpoint == 'xs' || breakpoint == 'sm' ? index % 2 !== 0 : index % 3 !== 0}
                            task={task}
                            onFinishedTaskClick={handleOnFinishedTaskClick}
                          />
                        )
                      })}
                    </div>
                    {hasMoreFinishedTasks && (
                      <Text
                        size="m"
                        className="mx-auto mt-6 cursor-pointer text-neutral100"
                        onClick={handleOnShowMoreClick}
                      >
                        {t('showMore')}
                      </Text>
                    )}
                  </div>
                )}
              </motion.div>
            </div>
          </LoaderWrapper>
        </>
      )}
    </div>
  )
}
