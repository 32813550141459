import { FlashCardAssignmentType, FlashCardResponseType, FullScreenFlashCardType } from '@app/data'
import {
  getSupportedFlashCardAssignmentTypes,
  getSupportedFlashCardResponseTypes,
  getSupportedFullscreenFlashCardTypes
} from '@app/modules/lesson/utils/getSupportedFlashCardTypes'

export const DEVELOPER_AUTH_KEYCLOAK_URI = 'DEV_keycloakUri'
export const DEVELOPER_DEFAULT_TENANT = 'DEV_Tenant'
export const DEVELOPER_BASE_URL = 'DEV_BaseUrl'

export const DEVELOPER_SUPPORTED_FLASHCARD_FULLSCREEN_TYPES = 'DEV_SupportedFlashcardsFullscreenTypes'
export const DEVELOPER_SUPPORTED_FLASHCARD_ASSIGNMENT_TYPES = 'DEV_SupportedFlashcardsAssignmentTypes'
export const DEVELOPER_SUPPORTED_FLASHCARD_RESPONSE_TYPES = 'DEV_SupportedFlashcardsResponseTypes'

export const DEVELOPER_ENABLE_LEARNING_PATH = 'DEV_EnableLearningPath'

class Constants {
  constructor() {
    this._DEFAULT_TENANT = localStorage.getItem(DEVELOPER_DEFAULT_TENANT) || `${import.meta.env.VITE_DEFAULT_TENANT}`
    this._BASE_API_URL = localStorage.getItem(DEVELOPER_BASE_URL) || `${import.meta.env.VITE_BASE_API_URL}`

    this._SUPPORTED_FLASHCARD_FULLSCREEN_TYPES = getSupportedFullscreenFlashCardTypes()
    this._SUPPORTED_FLASHCARD_ASSIGNMENT_TYPES = getSupportedFlashCardAssignmentTypes()
    this._SUPPORTED_FLASHCARD_RESPONSE_TYPES = getSupportedFlashCardResponseTypes()

    this._ENABLE_LEARNING_PATH = false

    const supportedFlashcardTypes = localStorage.getItem(DEVELOPER_SUPPORTED_FLASHCARD_FULLSCREEN_TYPES)
    this._SUPPORTED_FLASHCARD_FULLSCREEN_TYPES = supportedFlashcardTypes
      ? (supportedFlashcardTypes.split(',') as FullScreenFlashCardType[])
      : getSupportedFullscreenFlashCardTypes()

    const supportedFlashCardAssignmentTypes = localStorage.getItem(DEVELOPER_SUPPORTED_FLASHCARD_ASSIGNMENT_TYPES)
    this._SUPPORTED_FLASHCARD_ASSIGNMENT_TYPES = supportedFlashCardAssignmentTypes
      ? (supportedFlashCardAssignmentTypes.split(',') as FlashCardAssignmentType[])
      : getSupportedFlashCardAssignmentTypes()

    const supportedFlashCardResponseTypes = localStorage.getItem(DEVELOPER_SUPPORTED_FLASHCARD_RESPONSE_TYPES)
    this._SUPPORTED_FLASHCARD_RESPONSE_TYPES = supportedFlashCardResponseTypes
      ? (supportedFlashCardResponseTypes.split(',') as FlashCardResponseType[])
      : getSupportedFlashCardResponseTypes()

    this._ENABLE_LEARNING_PATH = localStorage.getItem(DEVELOPER_ENABLE_LEARNING_PATH) === 'true'
  }

  clearAllAsyncValues() {
    return localStorage.clear()
  }

  private _DEFAULT_TENANT: string = `${import.meta.env.VITE_DEFAULT_TENANT}`
  private _BASE_API_URL: string = `${import.meta.env.VITE_BASE_API_URL}`

  private _SUPPORTED_FLASHCARD_FULLSCREEN_TYPES: FullScreenFlashCardType[] = getSupportedFullscreenFlashCardTypes()
  private _SUPPORTED_FLASHCARD_ASSIGNMENT_TYPES: FlashCardAssignmentType[] = getSupportedFlashCardAssignmentTypes()
  private _SUPPORTED_FLASHCARD_RESPONSE_TYPES: FlashCardResponseType[] = getSupportedFlashCardResponseTypes()

  private _ENABLE_LEARNING_PATH: boolean = false

  public get DEFAULT_TENANT() {
    return this._DEFAULT_TENANT
  }

  public set DEFAULT_TENANT(newValue: string) {
    localStorage.setItem(DEVELOPER_DEFAULT_TENANT, newValue)
    this._DEFAULT_TENANT = newValue
  }

  public get BASE_API_URL() {
    return this._BASE_API_URL
  }

  public set BASE_API_URL(newValue: string) {
    localStorage.setItem(DEVELOPER_BASE_URL, newValue)
    this._BASE_API_URL = newValue
  }

  public get SUPPORTED_FLASHCARD_FULLSCREEN_TYPES() {
    return this._SUPPORTED_FLASHCARD_FULLSCREEN_TYPES
  }

  public set SUPPORTED_FLASHCARD_FULLSCREEN_TYPES(newValue: FullScreenFlashCardType[]) {
    localStorage.setItem(DEVELOPER_SUPPORTED_FLASHCARD_FULLSCREEN_TYPES, newValue.join(','))
    this._SUPPORTED_FLASHCARD_FULLSCREEN_TYPES = newValue
  }

  public get SUPPORTED_FLASHCARD_ASSIGNMENT_TYPES() {
    return this._SUPPORTED_FLASHCARD_ASSIGNMENT_TYPES
  }

  public set SUPPORTED_FLASHCARD_ASSIGNMENT_TYPES(newValue: FlashCardAssignmentType[]) {
    localStorage.setItem(DEVELOPER_SUPPORTED_FLASHCARD_ASSIGNMENT_TYPES, newValue.join(','))
    this._SUPPORTED_FLASHCARD_ASSIGNMENT_TYPES = newValue
  }

  public get SUPPORTED_FLASHCARD_RESPONSE_TYPES() {
    return this._SUPPORTED_FLASHCARD_RESPONSE_TYPES
  }

  public set SUPPORTED_FLASHCARD_RESPONSE_TYPES(newValue: FlashCardResponseType[]) {
    localStorage.setItem(DEVELOPER_SUPPORTED_FLASHCARD_RESPONSE_TYPES, newValue.join(','))
    this._SUPPORTED_FLASHCARD_RESPONSE_TYPES = newValue
  }

  public get ENABLE_LEARNING_PATH() {
    return this._ENABLE_LEARNING_PATH
  }

  public set ENABLE_LEARNING_PATH(newValue: boolean) {
    localStorage.setItem(DEVELOPER_ENABLE_LEARNING_PATH, String(newValue))
    this._ENABLE_LEARNING_PATH = newValue
  }

  // App environment
  public ENVIRONMENT = import.meta.env.MODE

  // Base API URLS
  public AUTH_ENDPOINT = import.meta.env.VITE_AUTH_ENDPOINT || ''
  public GRAPHQL_ENDPOINT = `${this.BASE_API_URL}/graphql`
  public RESET_PASSWORD_ENDPOINT = `${this.BASE_API_URL}/changePassword`

  // AUTHORIZATION
  public AUTH_TOKEN_EXPIRATION_THRESHOLD = Number(import.meta.env.VITE_AUTH_TOKEN_EXPIRATION_THRESHOLD)
  public AUTH_KEYCLOAK_URI = `${this.AUTH_ENDPOINT}/realms/${this._DEFAULT_TENANT}/protocol/openid-connect`
  public AUTH_KEYCLOAK_REGISTER_URL = `${this.AUTH_KEYCLOAK_URI}/registrations`
  public AUTH_LOGIN_URL: string = `${this.AUTH_KEYCLOAK_URI}/token`
  public AUTH_REFRESH_TOKEN_URL = `${this.AUTH_KEYCLOAK_URI}/token`
  public AUTH_LOGOUT_URL = `${this.AUTH_KEYCLOAK_URI}/logout`
  public AUTH_KEYCLOAK_CLIENT_ID = import.meta.env.VITE_AUTH_KEYCLOAK_CLIENT_ID || ''
  public AUTH_CONTENT_TYPE = 'application/x-www-form-urlencoded'

  // Azure speech
  public SPEECH_KEY = import.meta.env.VITE_SPEECH_KEY || ''
  public SPEECH_REGION = import.meta.env.VITE_SPEECH_REGION || ''
  public SPEECH_TO_TEXT_API = import.meta.env.VITE_SPEECH_TO_TEXT_API || ''
  public TEXT_TO_SPEECH_API = import.meta.env.VITE_TEXT_TO_SPEECH_API || ''

  // Timeout to show the right answer
  public SHOW_THE_RIGHT_ANSWER_TIMEOUT_MS = 10_000

  // Timeout between displaying the CORRECT answer and going to next lesson
  public BETWEEN_LESSONS_CORRECT_DELAY_MS = 1500

  // Timeout between displaying the ALMOST_CORRECT answer and going to next lesson
  public BETWEEN_LESSONS_ALMOST_CORRECT_DELAY_MS = 2000

  // Timeout between displaying the INCORRECT answer and going to next lesson
  public BETWEEN_LESSONS_INCORRECT_DELAY_MS = 3000

  // Delay in presenting the validation result for the provided answer
  public BETWEEN_ANSWERS_DELAY_DURATION_MS = 500

  // in percent
  public MAX_VISIBLE_PROGRESS = 100
  public MIN_VISIBLE_PROGRESS = 10

  // Threshold for success animation text
  public SUCCESSFUL_LEVEL_FOR_ANIMATION_TEXT = 60

  // Timeout in gql request
  public APOLLO_DEFAULT_TIMEOUT = 10_000

  public APOLLO_MAX_RETRIES = 3
}

export default new Constants()
