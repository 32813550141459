import { ReactNode, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useClickAway } from 'react-use'
import classNames from 'classnames'
import { PlusCircle, QrCode02 } from '@untitled-ui/icons-react'

// import { ImageContent } from '@app/assets/ImageContent'
import { Heading, LoaderWrapper } from '@app/components'
import { Pages } from '@app/config/router/Pages'
import { useTranslation } from '@app/locales'
import { useStudentsRanks } from '@app/modules/ranks/data'
import { useProfileSettings } from '@app/modules/settings/data'
import { useStudentInfo } from '@app/modules/tasks/data'
import { isInStandAloneMode, isIOSWebView } from '@app/utils/commonUtils'
import { subjectToFlagRoundIcon } from '@app/utils/iconUtils'

import { localSettingsStore } from '../../store/localSettingsStore'
import { GoBackBtn } from '../GoBackBtn'

import './MainHeader.styles.css'

type Props = {
  className?: string
  isBackBtnVisible?: boolean
  isQrLoginBtnVisible?: boolean
  isXpBarVisible?: boolean
  isSwitchClassEnable?: boolean
  isSwitchClassBannerVisible?: boolean
  onBackBtnClick?: () => void
  toggleSwitchClassBanner?: (flag?: boolean) => void
  title?: ReactNode
}

export const MainHeader = ({
  className,
  isBackBtnVisible = false,
  isQrLoginBtnVisible = false,
  isXpBarVisible = false,
  isSwitchClassEnable = false,
  isSwitchClassBannerVisible = false,
  onBackBtnClick,
  toggleSwitchClassBanner,
  title = ''
}: Props) => {
  const { t } = useTranslation(['settings', 'common'])
  const navigate = useNavigate()

  const { studentProfile, loading, refetching, error } = useProfileSettings()
  const { classrooms } = studentProfile || {}

  const classroomId = localSettingsStore.useStore((state) => state.classroomId)

  const { studentInfo } = useStudentInfo()
  const { overallRanks } = useStudentsRanks(classroomId, { skip: !studentInfo?.student.classrooms || !classroomId })
  const totalPoints = overallRanks.find((rank) => rank.currentStudent)?.points || 0

  const selectedClassroom = classrooms?.find((classroom) => classroom.id === classroomId)
  const SelectedClassroomFlag = selectedClassroom
    ? subjectToFlagRoundIcon[selectedClassroom.subject]
    : subjectToFlagRoundIcon['ENGLISH'] // just placeholder

  const ref = useRef(null)
  useClickAway(ref, () => {
    toggleSwitchClassBanner?.(false)
  }, ['click'])

  useEffect(() => {
    if (studentInfo?.student.classrooms) {
      if (studentInfo.student.classrooms.length > 0) {
        const belongsToClassroom = studentInfo?.student.classrooms?.some((classroom) => classroom.id === classroomId)

        if (!belongsToClassroom) {
          // default we select the first classroom
          localSettingsStore.setClassroomId(studentInfo?.student.classrooms[0].id)
        }
      } else {
        localSettingsStore.setClassroomId(undefined)
      }
    }
  }, [classroomId, studentInfo?.student.classrooms])

  const handleOnBackClick = () => {
    return onBackBtnClick ? onBackBtnClick() : navigate(-1)
  }

  const handleOpenSocialActivityLogin = () => {
    navigate(Pages.SOCIAL_ACTIVITY_LOGIN)
  }

  const handleOnAddClassroomClick = () => {
    navigate(Pages.SETTINGS_ADD_CLASSROOM)
  }

  useEffect(() => {
    if (!isInStandAloneMode() && !isIOSWebView()) {
      const header = document.querySelector('header')
      if (header) {
        header.style.paddingTop = '16px'
      }
    }
  }, [])

  return (
    <header
      ref={ref}
      className={classNames(
        'absolute left-0 z-[100] pt-2 md:rounded-b-2xl',
        isSwitchClassBannerVisible && 'bg-[#5140bd]',
        (isXpBarVisible || isBackBtnVisible || isSwitchClassBannerVisible) && 'w-full',
        className
      )}
    >
      <div className="flex h-12 w-full flex-row items-center justify-between px-4">
        <div className="flex h-full flex-1 items-start">
          {isSwitchClassEnable && (
            <button className="h-12 w-12 text-neutral50" onClick={() => toggleSwitchClassBanner?.()}>
              {classroomId && <SelectedClassroomFlag />}
            </button>
          )}

          {isQrLoginBtnVisible && (
            <button className="h-12 w-12 text-neutral50" onClick={handleOpenSocialActivityLogin}>
              <QrCode02 />
            </button>
          )}

          {isBackBtnVisible && <GoBackBtn onClick={handleOnBackClick} />}
        </div>

        <div className="flex-2 flex w-[70%] items-center justify-center text-center">
          <Heading size="xs" className="text-neutral50">
            {title}
          </Heading>
        </div>

        <div className="flex flex-1 items-center justify-end">
          {isXpBarVisible && (
            <div className="flex h-full w-[100px] items-center justify-end md:w-[125px]">
              <span
                className="text-nowrap text-left font-[GhostKidAOEPro] text-[20px] text-fill-transparent"
                style={{
                  background: 'linear-gradient(180deg, #FF9C41 41.7%, #FF8413 59.93%)',
                  backgroundClip: 'text',
                  WebkitBackgroundClip: 'text'
                }}
              >
                {totalPoints.toLocaleString()} {t('point')}
              </span>
            </div>
          )}
        </div>
      </div>

      {isSwitchClassBannerVisible && (
        <div className="switchWrapper mt-2 flex h-32 w-full flex-row items-end justify-start gap-4 overflow-x-auto border-t-2 border-t-[#6e5dd4] px-2 pt-4">
          <LoaderWrapper loading={loading || refetching} error={!!error}>
            {/* existing options */}
            {classrooms?.map((classroom) => {
              const LanguageFlag = subjectToFlagRoundIcon[classroom.subject] || subjectToFlagRoundIcon['ENGLISH'] // default value
              const fullObjectName = classroom.name + ` - ${t(`subject.${classroom.subject}`)}`

              return (
                <div
                  key={classroom.id}
                  className="flex h-full min-w-20 max-w-20 flex-col items-center justify-start gap-2"
                >
                  <div
                    className={classNames(
                      'flex h-12 w-12 cursor-pointer items-center justify-center rounded-full',
                      classroomId === classroom.id ? 'border-2 border-[#d6cfff]' : ''
                    )}
                    onClick={() => {
                      localSettingsStore.setClassroomId(classroom.id)
                      toggleSwitchClassBanner?.(false)
                    }}
                  >
                    <LanguageFlag className="h-10 w-10" />
                  </div>
                  <span
                    className={classNames(
                      'line-clamp-2 text-center text-neutral50',
                      classroomId === classroom.id ? 'font-bold' : ''
                    )}
                  >
                    {fullObjectName}
                  </span>
                </div>
              )
            })}

            {/* add new option */}
            <div className="flex h-full min-w-20 max-w-20 flex-col items-center justify-start gap-2">
              <div
                className="flex h-12 w-12 cursor-pointer items-center justify-center rounded-full"
                onClick={handleOnAddClassroomClick}
              >
                <PlusCircle className="h-10 w-10 text-[#a092ff]" />
              </div>

              <span className="line-clamp-2 text-center text-neutral50">{t('classrooms.addNew')}</span>
            </div>
          </LoaderWrapper>
        </div>
      )}
    </header>
  )
}

// const AllClassIcon = ({ className }: { className?: string }) => {
//   return (
//     <img
//       src={ImageContent.common.switchAllLanguages}
//       alt="All languages"
//       className={classNames('mx-auto h-10 w-10 rounded-full', className)}
//     />
//   )
// }
