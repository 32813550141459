import { App } from '@capacitor/app'
import { Capacitor } from '@capacitor/core'

import {
  FlashCardAssignmentType,
  FlashCardResponseType,
  FullScreenFlashCardType,
  useReportBugInLearningFeatureMutation
} from '@app/data'
import { useTranslation } from '@app/locales'
import { authStore } from '@app/modules/auth/store/authStore'
import { showToast } from '@app/utils/commonUtils'
import { logError } from '@app/utils/logsUtils'

import packageJson from '../../../../package.json'

type Props = {
  learningFeatureId?: string
  flashCardFullScreenType?: FullScreenFlashCardType
  flashCardAssignmentType?: FlashCardAssignmentType
  flashCardResponseType?: FlashCardResponseType
  logMessage?: string
}

export const useReportBug = ({
  flashCardFullScreenType,
  flashCardAssignmentType,
  flashCardResponseType,
  learningFeatureId,
  logMessage
}: Props) => {
  const [reportBugMutation, { loading }] = useReportBugInLearningFeatureMutation()
  const { t } = useTranslation('common')

  const reportBug = async (bugDescription: string) => {
    const userId = authStore.useStore.getState().userInfo?.userId || ''

    try {
      let version = ''
      let build = ''

      if (Capacitor.isNativePlatform()) {
        const appInfo = await App.getInfo()
        version = appInfo.version
        build = appInfo.build
      }

      const appVersion = `${packageJson.version}/${version}-Build(${build})`

      await reportBugMutation({
        variables: {
          userId,
          learningFeatureId,
          bugDescription,
          os: Capacitor.getPlatform(),
          appVersion,
          flashCardFullScreenType,
          flashCardAssignmentType,
          flashCardResponseType,
          logMessage
        }
      })
    } catch (err) {
      logError(err, 'useLessonContent', 'reportBug')
      showToast(t('errorGlobal'), { type: 'error', toastId: 'reportBug' })
    }
  }

  return {
    loading,
    reportBug
  }
}
