import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLocalStorage } from 'react-use'
import { App } from '@capacitor/app'
import { Capacitor } from '@capacitor/core'
import { motion } from 'framer-motion'
import { ChevronRight, Edit01, PlusCircle } from '@untitled-ui/icons-react'

import packageJson from '@app/../package.json'
import { Button } from '@app/components'
import { Pages } from '@app/config/router/Pages'
import Constants from '@app/constants'
import { useTranslation } from '@app/locales'
import i18n from '@app/locales/i18n'
import { languageCodeToShort, SupportedAppLanguage } from '@app/locales/types'
import { MainHeader, SettingsLayout } from '@app/modules/common'
import { localSettingsStore } from '@app/modules/common/store/localSettingsStore'
import { showToast } from '@app/utils/commonUtils'
import { subjectToFlagRoundIcon } from '@app/utils/iconUtils'

import { useProfileSettings } from '../data'

const DEVELOPER_MODE = 'developer_mode'
const DEVELOPER_MODE_CLICKS = 10

export const SettingsPage = () => {
  const navigate = useNavigate()
  const { t } = useTranslation(['settings', 'common'])

  const [numberOfClick, setNumberOfClick] = useState<number>(0)
  const [isEditModeOn, setIsEditModeOn] = useState(false)
  const [isDeveloperEnabled, setIsDeveloperEnabled] = useLocalStorage(DEVELOPER_MODE, false)
  const [editedFirstName, setEditedFirstName] = useState('')
  const [editedLastName, setEditedLastName] = useState('')
  const [appVersion, setAppVersion] = useState('')

  const appLanguage = localSettingsStore.useStore((state) => state.appLanguage)

  const { studentProfile, saveUserProfile } = useProfileSettings()
  const { userProfile, classrooms } = studentProfile || {}
  const { firstName, lastName, username } = userProfile || {}

  const handleOnProfileSave = () => {
    setIsEditModeOn(false)
    saveUserProfile({ firstName: editedFirstName, lastName: editedLastName })
  }

  useEffect(() => {
    if (isEditModeOn) {
      setEditedFirstName(firstName || '')
      setEditedLastName(lastName || '')
    }
  }, [isEditModeOn])

  useEffect(() => {
    if (numberOfClick >= DEVELOPER_MODE_CLICKS && !isDeveloperEnabled) {
      setIsDeveloperEnabled(true)
      showToast('Developer mode enabled', { type: 'info' })
    }
  }, [isDeveloperEnabled, numberOfClick])

  useEffect(() => {
    showToast(Capacitor.isNativePlatform(), { type: 'info' })
    if (Capacitor.isNativePlatform()) {
      const getAppVersion = async () => {
        const info = await App.getInfo()
        setAppVersion(info.version)
      }

      getAppVersion()
    }
  }, [])

  const handleOnAppLanguageClick = () => {
    navigate(Pages.SETTINGS_APP_LANGUAGE)
  }

  const handleChangePasswordClick = () => {
    const changePasswordUrl = `${Constants.AUTH_ENDPOINT}/realms/${Constants.DEFAULT_TENANT}/protocol/openid-connect/auth?response_type=code&client_id=${Constants.AUTH_KEYCLOAK_CLIENT_ID}&kc_action=UPDATE_PASSWORD&redirect_uri=${window.location.origin}${Pages.SETTINGS}&kc_locale=${languageCodeToShort[i18n.language]}`

    // Redirect the user to the change password page
    window.location.href = changePasswordUrl
  }

  const handleOnAddClassroomClick = () => {
    navigate(Pages.SETTINGS_ADD_CLASSROOM)
  }

  const handleOnDevModeClick = () => {
    navigate(Pages.SETTINGS_DEVELOPER_MODE)
  }

  const handleOnBackClick = () => {
    navigate(Pages.DASHBOARD_MY_PROFILE)
  }

  const LanguageFlagRoundIcon = subjectToFlagRoundIcon[appLanguage || SupportedAppLanguage.CZECH]

  return (
    <SettingsLayout>
      <MainHeader title={t('settings.title')} isBackBtnVisible onBackBtnClick={handleOnBackClick} />

      <div className="mt-16 flex h-full w-full max-w-screen-md flex-col pt-4">
        {/* Profile */}
        <div className="flex w-full flex-col gap-1 p-4">
          <span className="font-semibold text-neutral100">{t('settings.profile')}</span>

          <div className="divide-[rgb(92, 90, 220)]/40 flex w-full flex-col divide-y-[0.75px] rounded-2xl bg-btn-primary-outline-bg-gradient">
            <div
              className="flex h-14 w-full flex-row items-center justify-between px-2 pl-3"
              onClick={() => setNumberOfClick(numberOfClick + 1)}
            >
              <span className="text-[#2C0077]">{t('personalInfo.firstName')}</span>
              <div className="flex flex-row gap-2">
                {isEditModeOn ? (
                  <input
                    value={editedFirstName}
                    className="rounded-lg px-3 py-1 outline-none"
                    minLength={2}
                    maxLength={20}
                    onChange={(e) => setEditedFirstName(e.target.value)}
                    type="text"
                  />
                ) : (
                  <span
                    className="flex cursor-pointer gap-2 font-bold text-[#2C0077]"
                    onClick={() => setIsEditModeOn(true)}
                  >
                    {firstName}
                    <Edit01 className="w-4" />
                  </span>
                )}
              </div>
            </div>

            <div className="flex h-14 w-full flex-row items-center justify-between px-2 pl-3">
              <span className="text-[#2C0077]">{t('personalInfo.lastName')}</span>
              <div className="flex flex-row gap-2">
                {isEditModeOn ? (
                  <input
                    value={editedLastName}
                    className="rounded-lg px-3 py-1 outline-none"
                    minLength={2}
                    maxLength={20}
                    onChange={(e) => setEditedLastName(e.target.value)}
                  />
                ) : (
                  <span
                    className="flex cursor-pointer gap-2 font-bold text-[#2C0077]"
                    onClick={() => setIsEditModeOn(true)}
                  >
                    {lastName}
                    <Edit01 className="w-4" />
                  </span>
                )}
              </div>
            </div>

            <div className="flex h-14 w-full flex-row items-center justify-between px-2 pl-3">
              <span className="text-[#2C0077]">{t('personalInfo.username')}</span>
              <div className="flex flex-row gap-2">
                <span className="font-bold text-[#2C0077]">{username}</span>
              </div>
            </div>
          </div>

          {isEditModeOn && (
            <motion.div initial={{ y: -50, opacity: 0 }} animate={{ y: 0, opacity: 1 }} onClick={handleOnProfileSave}>
              <Button>{t('save')}</Button>
            </motion.div>
          )}
        </div>

        {/* Change Password */}
        <div className="flex w-full flex-col gap-1 p-4">
          <span className="font-semibold text-neutral100">{t('settings.password')}</span>

          <div className="flex w-full cursor-pointer flex-col rounded-2xl bg-btn-primary-outline-bg-gradient">
            <div
              className="flex h-14 w-full flex-row items-center justify-between rounded-2xl px-2"
              onClick={handleChangePasswordClick}
            >
              <span className="font-bold text-[#2C0077]">{t('settings.changePassword')}</span>

              <ChevronRight className="text-[#52287C]" />
            </div>
          </div>
        </div>

        {/* App language */}
        <div className="flex w-full flex-col gap-1 p-4">
          <span className="font-semibold text-neutral100">{t('settings.appLanguage')}</span>

          <div className="flex w-full cursor-pointer flex-col rounded-2xl bg-btn-primary-outline-bg-gradient">
            <div
              className="flex h-14 w-full flex-row items-center justify-between rounded-2xl px-2"
              onClick={handleOnAppLanguageClick}
            >
              <div className="flex flex-row items-center gap-3">
                <div className="h-10 w-10 overflow-hidden rounded-full">
                  <LanguageFlagRoundIcon className="h-10 w-10" />
                </div>
                <span className="font-bold text-[#2C0077]">{t(`appLanguage.${appLanguage}`)}</span>
              </div>

              <ChevronRight className="text-[#52287C]" />
            </div>
          </div>
        </div>

        {/* Classrooms */}
        <div className="flex w-full flex-col gap-1 p-4">
          <span className="font-semibold text-neutral100">{t('classrooms.title')}</span>
          <div className="divide-[rgb(92, 90, 220)]/40 flex w-full flex-col divide-y-[0.75px] rounded-2xl bg-btn-primary-outline-bg-gradient">
            {classrooms?.map((classroom) => {
              const LanguageFlag = subjectToFlagRoundIcon[classroom.subject] || subjectToFlagRoundIcon['ENGLISH'] // default value
              const fullObjectName = classroom?.name + ` - ${t(`subject.${classroom.subject}`)}`

              return (
                <div key={classroom.id} className="flex h-14 w-full flex-row items-center justify-between px-2">
                  <div className="flex flex-row items-center gap-3">
                    <div className="h-10 w-10 overflow-hidden rounded-full">
                      <LanguageFlag className="h-10 w-10" />
                    </div>
                    <span className="font-bold text-[#2C0077]">{fullObjectName}</span>
                  </div>
                </div>
              )
            })}
            <div
              className="flex h-14 w-full flex-row items-center justify-between px-2 pl-3"
              onClick={handleOnAddClassroomClick}
            >
              <span className="text-[#3C51C1]">{t('classrooms.addNew')}</span>
              <PlusCircle className="text-[#52287C]" />
            </div>
          </div>
        </div>

        {/* Developer mode */}
        {isDeveloperEnabled && (
          <div className="flex w-full flex-col gap-1 p-4">
            <span className="font-semibold text-neutral100">Developer mode</span>

            <div
              className="flex w-full flex-col rounded-2xl bg-btn-primary-outline-bg-gradient"
              onClick={handleOnDevModeClick}
            >
              <div className="flex h-14 w-full flex-row items-center justify-between rounded-2xl px-2 pl-3">
                <span className="font-bold text-[#2C0077]">Enter developer page</span>
                <ChevronRight className="text-[#52287C]" />
              </div>
            </div>
          </div>
        )}

        {/* Version */}
        <div className="flex w-full flex-col gap-1 p-4">
          <span className="font-semibold text-neutral100">{t('version')}</span>

          <div className="flex w-full flex-col rounded-2xl bg-btn-primary-outline-bg-gradient">
            <div className="flex h-14 w-full flex-row items-center justify-between rounded-2xl px-2 pl-3">
              <span className="font-bold text-[#3C51C1]">{packageJson.version}</span>
            </div>
          </div>

          {appVersion && (
            <div className="flex w-full flex-col rounded-2xl bg-btn-primary-outline-bg-gradient">
              <div className="flex h-14 w-full flex-row items-center justify-between rounded-2xl px-2 pl-3">
                <span className="font-bold text-[#3C51C1]">App - {appVersion}</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </SettingsLayout>
  )
}
